import React from "react"
import SEO from "../../../components/seo"
import Layout from "../../../components/layout";
import { itemListProd } from "../../../utils/sidebar/item-list"
import Container from "../../../components/container";
import { graphql } from "gatsby"
import VideoPlay from "../../../components/common/videoPlay";

class ProductFunctionTopo extends React.Component {

  render() {
    const { location, data } = this.props;
    const _videos = data.markdownRemark.frontmatter.videos
    return (
      <Layout location={location} itemList={itemListProd} contentTitle={data.markdownRemark.frontmatter.title}>
          <SEO title={data.markdownRemark.frontmatter.title} />
          {/* <div css={{ ...styles.contentHeader, ...styles.sticky }}>
              <h2 css={styles.contentTitle}>
                  <span>
                      {data.markdownRemark.frontmatter.title}
                  </span>
              </h2>
          </div> */}
          <Container overrideCSS={{maxWidth: '100%', padding: 24, minHeight: 'calc(100vh - 392px)'}}>
            <div css={styles.font} dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}></div>
            {_videos.map((item, i) => (
              <div key={i}>
                <VideoPlay videoProps={item} />
              </div>
            ))}
          </Container>
          {/* <div css={styles.footer}>
            <Footer />
          </div> */}
      </Layout>
    )
  }
}

const styles = {

}

export default ProductFunctionTopo

export const pageQuery = graphql`
  query ProductFunctionTopoQuery {
    markdownRemark(
      frontmatter: { type: { eq: "function-topo" } }
    ) {
      html
      frontmatter {
        title
        videos {
          videoImg {
            publicURL
          }
          videoImg1 {
            publicURL
          }
          videoUrl {
            publicURL
          }
        }
      }
    }
  }
`
